<template>
  <div class="non-analysis">
    <header class="non-analysis-header">
      <nav class="op-category-tabs">
        <div v-for="item in siteList" :key="item.site_id" :class="{'active': item.site_id == site}" @click="site = item.site_id">{{item.proxy_name}}</div>
      </nav>
      <el-button type="primary" @click="add()">添加</el-button>
    </header>
    <DragTable
      class="drag-table"
      :listQuery="list"
      :header="header"
      :sort="true"
      @change="handle">
      <template #state="{row}">{{row.state == 1?'停用':'启用'}}</template>
      <template #control="{row}">
        <span class="word-color" @click="edit(row)">{{row.state == 1 ? '启用':'停用'}}</span>
        <span class="word-color" @click="add(row)">编辑</span>
        <span class="word-color" @click="del(row)">删除</span>
      </template>
    </DragTable>
    <go-dialog v-model="visible" width="70vw">
      <div>
        <ManageItem title="名称">
          <el-input v-model="params.name" maxlength="6" placeholder="请输入名称"></el-input>
        </ManageItem>
        <ManageItem title="分类选择">
          <category-table :info="site_category" :siteid="site" @change="getCategoryInfo"></category-table>
        </ManageItem>
      </div>
      <footer class="dialog-footer">
        <el-button type="primary" @click="visible=false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </footer>
    </go-dialog>
  </div>
</template>
<script>
import CategoryTable from "./components/table.vue";
import ManageItem from "@/components/commonUI/ManageItem";

export default {
  components: { ManageItem, CategoryTable },
  data() {
    return {
      params: {
        name: '',
        id: '',
        site_id: '',
        category_ids: []
      },
      header: [
        {name: '序号', value: 'sort'},
        // {name: '排序', value: 'sort'},
        {name: '名称', value: 'name'},
        {name: '状态', slot: 'state'},
        {name: '更新时间', value: 'update_time'},
        {name: '操作人', value: 'operator'},
        {name: '操作', slot: 'control'},
      ],
      list: {
        data: []
      },
      siteList: [],
      site: '',
      visible: false,
      site_category: {}
    }
  },
  watch: {
    visible(val) {
      if(!val) {
        this.clear()
      }
    },
    site() {
      this.params.site_id = this.site
      this.getList()
    }
  },
  mounted() {
    this.getSite()
    if(this.params.site_id) {
      this.getList()
    }
  },
  methods: {
    getSite() {
      this.$axios.get(`/common/business/site/getSimplifyList`)
      .then(res => {
        res.data.forEach(item=>{
          const arr = ['mercari','booth','yahooauction','surugaya', 'amazon', 'toranoana', 'melonbooks', 'rakutenbooks']
          if(arr.includes(item.proxy_name)) {
            this.siteList.push(item)
          }
        })
        if(this.siteList.length>0){
          this.site = this.siteList[1].site_id
          this.getList()
        }
      })
      
    },
    getList() {
      this.$axios.get(`/cms/sitePage/categoryConfig/list`,{
        params: {
          site_id: this.site
        }
      })
      .then(res=>{
        this.list.data = res.data
      })
    },
    getCategoryInfo(data) {
        this.params.category_ids = data[this.site]
    },
    add(data) {
      if(!data) {
        this.visible = true
        return
      }
      this.$axios.get(`/cms/sitePage/categoryConfig/detail/${data.id}`)
      .then(res=>{
        this.visible = true
        this.params.id = res.data.id
        this.params.name = res.data.name
        this.params.site_id = res.data.site_id
        this.params.category_ids = res.data.category_ids
        this.site_category[this.site] = res.data.category_ids
      })
      
    },
    async del(data) {
      const confirm = await this.$goDialog('确定要删除吗？')
      if(!confirm) return
      this.$axios.delete(`/cms/sitePage/categoryConfig/delete/${data.id}`)
      .then(res=>{
        this.$message.success(res.msg)
        this.getList()
      })
      
    },
    edit(data) {
      this.$axios.post(`/cms/sitePage/categoryConfig/set_state`,{
        id: data.id,
        state: data.state == 1 ? 2 : 1
      })
      .then(res=>{
        this.$message.success(res.msg)
        this.getList()
      })
      
    },
    async handle(data) {
      try {
        const arr = []
        data.forEach((item,i) => {
          arr.push({ id: item.id, sort: i+1 })
        });
        await this.$axios.post('/cms/sitePage/categoryConfig/sort',{
          sort_list: arr
        })
      } catch (error) {
        this.getList()
        this.$message.error(error)
      }
    },
    clear() {
      for(let key in this.params) {
        this.params[key] = ''
      }
      this.site_category = {}
    },
    confirm() {
      this.params.site_id = this.site
      if(!this.params.name) {
        this.$message.warning('请输入商品名称')
        return
      }
      if(this.params.name && !this.params.name.trim()) {
        this.$message.warning('请输入商品名称')
        return
      }
      this.$axios.post(`/cms/sitePage/categoryConfig/save`,this.params)
      .then(res=>{
        this.$message.success(res.msg)
        this.visible = false
        this.getList()
      })
      
    },
  }
}
</script>
<style lang="scss" scoped>
.word-color{
  margin-right: 10px;
}
.dialog-footer{
  margin-top: 20px;
}
.op-category-tabs{
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  &>div{
    flex-shrink: 0;
    max-width: 300px;
    padding: 8px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    &.active{
      background-color: #4f5bff;
      color: #fff;
      border-color: #4f5bff;
    }
  }
}
.non-analysis-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>